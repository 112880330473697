import React, { useEffect, Suspense, useState } from 'react';
import './App.css';

import { Switch, Redirect, withRouter, useLocation } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { corporationAtom } from '@/state/atom/corporationAtom';

import Amplify from '@aws-amplify/core';
import PubSub from '@aws-amplify/pubsub';
import awsmobile from './aws-exports';
import { Hub } from 'aws-amplify';

import LoadingIndicator from '@/component/common/LoadingIndicator';
import Constant from '@/common/constant/Constant';
import Utils from './common/utils/utils';
import localStorageUtil from './common/utils/localStorageUtil';
import { useRecoilValue } from 'recoil';
import ErrorBoundary from './ErrorBoundary';
Amplify.configure(awsmobile);
PubSub.configure(awsmobile);

const LoginLayoutRoute = React.lazy(() => import('@/component/common/layout/LoginLayout'));
const WrapLayoutRoute = React.lazy(() => import('@/component/common/layout/WrapLayoutRoute'));
const ShareLinkTicketLayoutRoute = React.lazy(() => import('@/component/common/layout/ShareLinkTicketLayoutRoute'));
const WrapLayout = React.lazy(() => import('@/component/common/layout/WrapLayout'));
const PublicKnowledgeLayoutRoute = React.lazy(() =>
    import('@/component/common/layout/PublicKnowledgeLayout')
);
const ChatBotLayoutRoute = React.lazy(() => import('@/component/common/layout/ChatBotLayoutRoute'));
const OrganizationLogin = React.lazy(() => import('@/component/login/OrganizationLogin'));
const IframeLayoutRoute = React.lazy(() => import('@/component/common/layout/IframeLayout'));
const CreateOrganization = React.lazy(() => import('@/component/organization/CreateOrganization'));
const CreateGuestContract = React.lazy(() =>
    import('@/component/guestContract/CreateGuestContract')
);
const PublicKnowledgeList = React.lazy(() =>
    import('@/component/publicPages/publicKnowledge/PublicKnowledgeList')
);
const PublicKnowledgeDetail = React.lazy(() =>
    import('@/component/publicPages/publicKnowledge/PublicKnowledgeDetail')
);
const ApprovePage = React.lazy(() => import('@/component/approvePage/ApprovePage'));
const ConfirmOrganization = React.lazy(() =>
    import('@/component/organization/ConfirmOrganization')
);
const CreateOrganizationSuccess = React.lazy(() =>
    import('@/component/organization/CreateOrganizationSuccess')
);
const UserRegisterRequest = React.lazy(() =>
    import('@/component/userRegisterRequest/UserRegisterRequest')
);
const CreateOrganizationGuestSuccess = React.lazy(() =>
    import('@/component/organization/CreateOrganizationGuestSuccess')
);
const WrapPanel = React.lazy(() => import('@/component/multiPanel/WrapPanel'));
const IframeLogin = React.lazy(() => import('@/component/supportIframe/IframeLogin'));
const AnonymousQA = React.lazy(() => import('@/component/supportIframe/AnonymousQA'));
const ForgotPassword = React.lazy(() => import('@/component/login/ForgotPassword'));
const ConfirmForgotPassword = React.lazy(() => import('@/component/login/ConfirmForgotPassword'));
const ApproveSuccess = React.lazy(() => import('@/component/organization/ApproveSuccess'));
const RejectSuccess = React.lazy(() => import('@/component/organization/RejectSuccess'));
const ChatBoxComponent = React.lazy(() => import('@/component/publicPages/chatBot/ChatBoxComponent'));
const SelectOrganization = React.lazy(() => import('@/component/login/SelectOrganization') )
const LogScreenLayout = React.lazy(() => import('@/component/common/layout/LogScreenLayout'));
const LogScreenPage = React.lazy(() => import('@/component/LogScreen/LogScreenPage'));
const LogScreenDetailPage = React.lazy(() => import('@/component/LogScreen/LogScreenDetailPage'));
const BulkActionLogScreenPage = React.lazy(() => import('@/component/LogScreen/BulkActionLogScreenPage'));
const BulkActionLogScreenDetailPage = React.lazy(() => import('@/component/LogScreen/BulkActionLogScreenDetailPage'));
const ShareTicket = React.lazy(() => import('@/component/shareTicket/index'));

function App(props) {
    const corporationDetail = useRecoilValue(corporationAtom);
    const [reloadPublicKnowledgeAssets, setReloadPublicKnowledgeAssets] = useState(false);
    const searchParams = new URLSearchParams(document.location.search)

    useEffect(() => {
        // console.log('process.env.NODE_ENV', process.env.NODE_ENV);
        const openPanel = searchParams.get('open-panel');
        if (!Utils.isEmptyString(openPanel)) {
            localStorageUtil.setNotificationRedirectPanelInfo(openPanel);
        }
        window.sliderPanel = null;
        const init = async () => {
            if (Hub) {
                Hub.listen(/.*/, data => {
                    // const { payload } = data;
                    // console.info('%cA new event has happened:', 'color: green;', payload);
                });
            }
        };
        init();
    }, []);

    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname;
        let currentPathArr = currentPath.split('/');

        switch (currentPath) {
            case '/solutiondesk/organization/create':
                document.title = Constant.DOCUMENT_TITLE.ORGANIZATION_CREATE;
                break;
            case '/solutiondesk/organization/success':
                document.title = Constant.DOCUMENT_TITLE.ORGANIZATION_SUCCESS;
                break;
            case '/guest-project/approve':
                document.title = Constant.DOCUMENT_TITLE.GUEST_PROJECT_APPROVE;
                break;
            case '/guest-project/reject':
                document.title = Constant.DOCUMENT_TITLE.GUEST_PROJECT_REJECT;
                break;
            case '/guest-contract/success':
                document.title = Constant.DOCUMENT_TITLE.GUEST_CONTRACT_SUCCESS;
                break;
            case '/forgot-password':
                document.title = Constant.DOCUMENT_TITLE.FORGOT_PASSWORD;
                break;
            case '/confirm-forgot-password':
                document.title = Constant.DOCUMENT_TITLE.CONFIRM_FORGOT_PASSWORD;
                break;
            default:
                // document.title = Constant.DOCUMENT_TITLE.DEFAULT;
                break;
        }

        if (
            currentPathArr.length > 1 &&
            currentPathArr[1] === 'guest-contract' &&
            currentPathArr[2] === 'approve'
        ) {
            document.title = Constant.DOCUMENT_TITLE.GUEST_CONTRACT_APPROVE;
        }

        if (currentPathArr.length > 1 && currentPathArr[1] === 'login') {
            document.title = `${Constant.DOCUMENT_TITLE.LOGIN} - ${corporationDetail.corporationName} - ${Constant.DOCUMENT_TITLE.DEFAULT}`;
        }

        if (
            currentPathArr.length > 1 &&
            currentPathArr[1] === 'organization' &&
            currentPathArr[2] === 'confirm'
        ) {
            document.title = Constant.DOCUMENT_TITLE.ORGANIZATION_CONFIRM;
        }
    }, [location, corporationDetail.corporationName]);

    let infoCorporation = localStorageUtil.getContractTypeAndSubDomainCorp();
    infoCorporation = Utils.convertJSONDataToObject(infoCorporation);
    let urlSignedIn= Utils.getUrlLinkCorporationSignedIn(infoCorporation.contractType, infoCorporation.subDomainInvite);

    const wildcardRedirectRoute =
        props.authState === 'signedIn' ? urlSignedIn : '/login';

    return (
        <Suspense fallback={<LoadingIndicator isLoading={true} />}>
            {/* <ErrorBoundary> */}
                <CssBaseline />
                <Switch>
                    <ShareLinkTicketLayoutRoute
                        exact
                        path="/share/:orgCode/:projectId/ticket/:ticketId"
                        component={ShareTicket}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/solutiondesk/organization/create"
                        component={CreateOrganization}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/selectcorp"
                        component={SelectOrganization}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />

                  
                    {/* <LoginLayoutRoute
                        exact
                        path="/guest-contract/approve/:info"
                        component={ApprovePage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/guest-contract/create/:contract"
                        component={CreateGuestContract}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    /> */}
                    <LoginLayoutRoute
                        exact
                        path="/userRegisterRequest/UserRegisterRequest"
                        component={UserRegisterRequest}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/solutiondesk/organization/success"
                        component={CreateOrganizationSuccess}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/guest-project/approve"
                        component={ApproveSuccess}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/guest-project/reject"
                        component={RejectSuccess}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/solutiondesk/organization/confirm/:confirmInfo"
                        component={ConfirmOrganization}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/guest-contract/success"
                        component={CreateOrganizationGuestSuccess}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/login/:orgCode"
                        component={OrganizationLogin}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/login/:orgCode/p/:orgInvitation"
                        component={OrganizationLogin}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/login/:orgCode/c/:orgInvitation"
                        component={OrganizationLogin}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/login"
                        component={OrganizationLogin}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    
                    <LoginLayoutRoute
                        exact
                        path="/forgot-password/:orgCode"
                        component={ForgotPassword}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />

                    <LoginLayoutRoute
                        exact
                        path="/forgot-password/:orgCode/c/:orgInvitation"
                        component={ForgotPassword}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />

                    <LoginLayoutRoute
                        exact
                        path="/forgot-password/:orgCode/p/:orgInvitation"
                        component={ForgotPassword}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />

                    <LoginLayoutRoute
                        exact
                        path="/forgot-password"
                        component={ForgotPassword}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <LoginLayoutRoute
                        exact
                        path="/confirm-forgot-password"
                        component={ConfirmForgotPassword}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <IframeLayoutRoute
                        exact
                        path="/iframe/login"
                        component={IframeLogin}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <PublicKnowledgeLayoutRoute
                        exact
                        path="/:subDomain/:projectId/kb"
                        component={PublicKnowledgeList}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        reloadPublicKnowledgeAssets={reloadPublicKnowledgeAssets}
                        setReloadPublicKnowledgeAssets={setReloadPublicKnowledgeAssets}
                    />
                    <PublicKnowledgeLayoutRoute
                        exact
                        path="/:subDomain/:projectId/kb/:id"
                        component={PublicKnowledgeDetail}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        reloadPublicKnowledgeAssets={reloadPublicKnowledgeAssets}
                        setReloadPublicKnowledgeAssets={setReloadPublicKnowledgeAssets}
                    />
                    {/* <IframeLayoutRoute
                        exact
                        path="/iframe/open-sdesk"
                        component={OpenSdeskIframe}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    /> */}
                    <IframeLayoutRoute
                        exact
                        path="/iframe/anonymous/qa"
                        component={AnonymousQA}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <ChatBotLayoutRoute
                        exact
                        path="/chatbot/:projectId"
                        component={ChatBoxComponent}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                    />
                    <WrapLayoutRoute
                        exact
                        path="/:orgCode"
                        component={WrapPanel}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={WrapLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/c/:orgInvitation/log-screen/bulk-actions"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/c/:orgInvitation/log-screen/bulk-actions/customer-support"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute
                        exact
                        path="/:orgCode/p/:orgInvitation"
                        component={WrapPanel}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={WrapLayout}
                    />

                    <WrapLayoutRoute
                        exact
                        path="/:orgCode/c/:orgInvitation"
                        component={WrapPanel}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={WrapLayout}
                    />

                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen"
                        component={LogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />

                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen"
                        component={LogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen/bulk-actions"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen/bulk-actions/customer-support"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen/bulk-actions"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen/bulk-actions/customer-support"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK}
                        component={BulkActionLogScreenPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen/:fileName"
                        component={LogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen/:fileName"
                        component={LogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />

                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen/bulk-actions/:fileName"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/log-screen/bulk-actions/customer-support/:fileName"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK}
                        component={BulkActionLogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen/bulk-actions/:fileName"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/c/:orgInvitation/log-screen/bulk-actions/:fileName"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.KNOWLEDGE}
                        component={BulkActionLogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <WrapLayoutRoute 
                        exact
                        path="/:orgCode/p/:orgInvitation/log-screen/bulk-actions/customer-support/:fileName"
                        logTicketType={Constant.BULK_ACTION_LOG_TICKET_TYPE.HELPDESK}
                        component={BulkActionLogScreenDetailPage}
                        authState={props.authState}
                        onStateChange={props.onStateChange}
                        wrapLayout={LogScreenLayout}
                    />
                    <Redirect path="*" to={wildcardRedirectRoute} />
                </Switch>
            {/* </ErrorBoundary> */}
        </Suspense>
    );
}

export default withRouter(App);
